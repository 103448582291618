<template>
  <div v-if="!isLoading">
    <!-- File Card Header -->
    <b-card class="material-card mb-2">
      <div class="d-flex">
        <div class="mr-1">
          <b-avatar rounded="lg" variant="light-success">
            <i class="card-icon ti ti-book-2 " />
          </b-avatar>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column ">
              <span class="text-muted ">Materi</span>
              <h6 class="font-weight-bolder small-gap-bottom">
                {{ classroomContent.title }}
              </h6>
            </div>
          </div>

          <span>
            {{ classroomContent.description }}
          </span>
        </div>
      </div>
    </b-card>

    <!-- Scrollable Content -->
    <div
      v-show="!isLoading && classroomContent.materials.length !== 0"
      class="vertical-scrollable"
    >
      <b-row>
        <!-- Material List -->
        <b-col
          v-for="material in classroomContent.materials"
          :key="material.id"
          xl="4"
          lg="6"
          md="6"
        >
          <!-- Video -->
          <b-card
            v-if="material.video_url !== null"
            :title="material.title"
            class="border-light-secondary"
            bg-variant="transparent"
          >
            <div class="my-1">
              <span class="">
                {{ material.description }}
              </span>
            </div>
            <div class="img-overlay text-center">
              <b-card-img
                class="preview-material"
                :src="baseMaterialBgImage"
                alt="Image"
              />
              <b-avatar class="preview-icon bg-white" variant="light-primary">
                <i
                  class="card-icon ti ti-player-play"
                  @click="showVideoModal(material.video_url)"
                />
              </b-avatar>
            </div>
            <div
              class="pt-1 mt-1 border-top d-flex justify-content-between card-action"
            >
              <span class="text-muted">
                {{ localizeISODate(material.created_at, 'ID-id') }}
              </span>
              <b-button
                size="sm"
                variant="outline-info"
                class="d-flex align-items-center"
                @click="downloadMaterial(material.video_url, material.title)"
              >
                <i class="ti ti-download" />
                <span class="small-gap-left">Download</span>
              </b-button>
            </div>
          </b-card>

          <!-- File -->
          <b-card
            v-if="material.file !== null"
            class="border-light-secondary"
            :title="material.title"
            bg-variant="transparent"
          >
            <div class="my-1">
              <span class="">
                {{ material.description }}
              </span>
            </div>
            <div class="img-overlay text-center">
              <b-card-img
                class="preview-material"
                :src="baseMaterialBgImage"
                alt="Image"
              />
              <b-badge
                class="preview-icon bg-white py-1 px-2"
                :variant="`light-${fileType(getExtension(material.file))}`"
              >
                <span class="card-icon ">
                  .{{ getExtension(material.file) }}
                </span>
              </b-badge>
            </div>
            <div
              class="pt-1 mt-1 border-top d-flex justify-content-between card-action"
            >
              <span class="text-muted">
                {{ localizeISODate(material.created_at, 'ID-id') }}
              </span>
              <b-button
                size="sm"
                variant="outline-info"
                class="d-flex align-items-center"
                :href="material.file"
                target="_blank"
              >
                <i class="ti ti-download" />
                <span class="small-gap-left">Download</span>
              </b-button>
            </div>
          </b-card>

          <!-- Link -->
          <b-card
            v-if="material.url !== null"
            :title="material.title"
            class="border-light-secondary"
            bg-variant="transparent"
          >
            <div class="my-1">
              <span class="">
                {{ material.description }}
              </span>
            </div>
            <div class="img-overlay ">
              <b-card-img
                class="preview-material"
                :src="baseMaterialBgImage"
                alt="Image"
              />
              <b-avatar class="preview-icon bg-white" variant="light-primary">
                <i
                  class="card-icon ti ti-link"
                  @click="redirectToLink(material.url)"
                />
              </b-avatar>
            </div>
            <div
              class="py-1 mt-1 border-top d-flex justify-content-between card-action"
            >
              <span class="text-muted">
                {{ localizeISODate(material.created_at, 'ID-id') }}
              </span>
              <b-button
                size="sm"
                variant="outline-info"
                class="d-flex align-items-center"
                @click="redirectToLink(material.url)"
              >
                <span class="small-gap-right">Buka</span>
                <i class="ti ti-chevron-right" />
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <!-- Empty State -->
    <div v-show="!isLoading && classroomContent.materials.length === 0">
      <b-row>
        <b-col class="d-flex justify-content-center mt-2">
          <div class="text-center pt-2">
            <b-img class="illustration-small" fluid :src="emptyStateImg" />
            <p class="mb-2">
              Belum Ada Materi Kelas
            </p>
            <b-button
              variant="primary"
              class="mb-1 btn-sm-block"
              @click="$router.back()"
              >Kembali</b-button
            >
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Loading Material -->
    <div v-if="isLoading" class="d-flex justify-content-center mt-4">
      <div class="text-center mt-4">
        <b-spinner variant="primary" class="mr-1" />
        <p class="mt-1">
          Memuat Daftar Materi
        </p>
      </div>
    </div>

    <!-- Preview Modal -->
    <div>
      <b-modal
        id="modal-video-preview"
        centered
        size="lg"
        title="Preview Materi"
        ok-only
        ok-title="Tutup"
      >
        <div>
          <video
            class="rounded"
            autoplay
            controls
            width="100%"
            :src="videoURL"
          />
        </div>
      </b-modal>
    </div>

    <!-- toast -->
    <b-toast id="toast-download-material" variant="primary" solid no-auto-hide>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-center mr-25">
          <strong class="mr-auto">
            Mengunduh Materi...
            <b id="download-percentage-title">0</b>
            %
          </strong>
        </div>
      </template>
      <span>
        Proses Mengunduh, Mohon Tunggu ... ( <b id="download-percentage">0</b>%
        )
      </span>
    </b-toast>
  </div>
</template>

<script>
// ** Third Party Components
import axios from 'axios'

// Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BBadge,
  BCardImg,
  BImg,
  BAvatar,
  BSpinner,
  BToast,
} from 'bootstrap-vue'

// Utils
import { checkAuthorizeRole } from '@/auth/utils'
import { localizeISODate, fileType } from '@core/utils/filter'
import client from '@/libs/http/axios-config'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BCardImg,
    BImg,
    BAvatar,
    BSpinner,
    BToast,
  },
  data() {
    return {
      // state
      isLoading: true,

      // assets
      baseMaterialBgImage: require('@/assets/images/component/material/material-bg.png'),
      sampleVideoMaterialBgImage: require('@/assets/media/sample/video/sample-video.png'),
      sampleVideoMaterial: require('@/assets/media/sample/video/sample-video.mp4'),
      emptyStateImg: require('@/assets/images/illustration/no-connection.svg'),
      videoURL: '',

      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      classroomContent: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    const { role, breadcrumb } = to.meta

    to.meta.breadcrumb[breadcrumb.length - 2].params = { id: to.params.id }
    next()
  },
  async mounted() {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    await client
      .get(
        `/students/classrooms/${this.classroomId}/class-contents/${this.contentId}`,
        config
      )
      .then((response) => {
        this.classroomContent = response.data.data

        this.isLoading = false
      })
      .catch((error) => {
        this.isLoading = false
        console.error(error)
      })
  },
  methods: {
    checkAuthorizeRole,
    localizeISODate,
    fileType,
    getExtension(filename) {
      return filename.split('.').pop()
    },

    // Show preview modal
    showVideoModal(fileURL) {
      console.log(fileURL)
      this.videoURL = fileURL
      this.$bvModal.show('modal-video-preview')
    },

    // Download
    downloadMaterial(fileURL, materialName) {
      this.$bvToast.show('toast-download-material', {
        solid: true,
        appendToast: true,
        toaster: 'b-toaster-bottom-right',
      })

      axios
        .get(fileURL, {
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            const total = parseFloat(progressEvent.total)
            const current = progressEvent.loaded

            const percentCompleted = Math.floor((current / total) * 100)

            document.getElementById(
              'download-percentage'
            ).innerHTML = percentCompleted
            document.getElementById(
              'download-percentage-title'
            ).innerHTML = percentCompleted
          },
        })
        .then((response) => {
          const href = URL.createObjectURL(response.data)
          const extension = this.getExtension(fileURL)

          // create "a" HTML element with href to file & click
          const link = document.createElement('a')
          link.href = href
          link.setAttribute('download', `${materialName}.${extension}`)
          document.body.appendChild(link)
          link.click()

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link)
          URL.revokeObjectURL(href)

          // hide toast
          this.$bvToast.hide('toast-download-material')
        })
    },

    // Redirect
    redirectToLink(link) {
      window.open(link, '_blank')
    },

    showDownloadProgress(percentage) {
      console.log(percentage)
      this.$bvToast.toast('Materi sedang diunduh', {
        title: 'Unduh Materi',
        variant: 'primary',
        solid: true,
      })
    },
  },
}
</script>

<style lang="scss">
.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.preview-material {
  max-height: 200px;
  object-fit: fill;
}

.preview-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
  font-size: 2rem;
}

.vertical-scrollable {
  position: relative;
  padding-right: 15px;
  height: calc(100vh - 330px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.illustration-small {
  height: 300px;
  object-fit: cover;
}

.min-height-screen {
  min-height: calc(100vh - 190px);
}

.b-toaster-top-right {
  top: 10px !important;
}
</style>
